<template>
  <div style="    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;">
    <div style="font-size: 150px;
    font-weight: 600;
    text-align: center;">404</div>
  </div>
</template>

<script>
export default {
  name: ""
}
</script>

<style scoped>

</style>